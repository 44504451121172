body{
  background-color: #282c34;
}

.App {
  display: Flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  color:rgb(219, 239, 245);
  font-family: 'Outfit', sans-serif;
  width: 100vw;
}

.Home{
  display: flex;
  flex-direction: column;
  align-items: center;
}

header{
  display: flex;
  flex-direction: row;
  margin-left:20%;
  width: 80vw;
  height: 10vh;
  margin-top: 20px ;
  font-family: 'Permanent Marker', cursive;
  font-size: larger;
}

main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
}

nav{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(65, 82, 95);
  margin-top: 7%;
  width: 14vw;
  height: 70vh;
  padding: 30px;
}
button{
  
      background-color: #95b3f0;
      border:2px solid rgb(8, 14, 16);
      /* border-radius: 25px; */

}
button:hover{
    -webkit-transform: scale(1, 1.2);
    -ms-transform: scale(1, 1.2);
    transform: scale(1, 1.2);

}

.search-form{
  margin-top: 10px;

}

form{
  margin-bottom: 20px;
}

.Market{
  display: flex;
  flex-direction: column;
  justify-content:center ;
  align-items: center;
  margin-top: 20vh;
  background-color: rgb(46, 69, 88);
  width: 90vw;
  margin-left: 50px;
  margin-right: 50px;
}

.item-map{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding:30px;
}


.Item{
  background-color: #282c34;
  width: 150px;
  height: 200px;
  margin: 20px;
}

.cart-display {
  padding: 20px;
  background-color: rgb(46, 69, 88);
}

.cart-click{
  background-color: rgb(140, 149, 157);
  padding: 5%;
  z-index: 1;
}

.cart-click:hover{
  background-color: rgb(169, 190, 209);
  cursor: pointer
}
#toucan{
  height:14vh;
  width: 5vw;
  margin: 1% 2% 5% 5%;
}

#cart-logo{
  height: 15vh;
  width:10vw;
  z-index: 1;
}

.cartNumLogo{
  background-color: rgb(62, 224, 62);
  padding: 2px;
  color:black;
  border-radius: 50%;
  font-size: 15px;
  display: block;
  text-align: center;
  height:28px;
  width:28px;
  margin-bottom:-60px;
  margin-left:15px;
  z-index: 2;
}

.fruit-pic{
  height: 20%;
  width: 20%;
}
#checkmark{
  height:15%;
  width:20%;
}

.register{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 50vh;
  width: 100vw;
}

.order{
  border: 3px solid rgb(135, 94, 77);
}

@media (min-width:800px) {
#register-form{
  margin-top: 20vh;
  height: 60vh;
  width: 25vw;
  background-color:rgb(46, 69, 88) ;
}
}

@media (max-width:800px) {
  header{
    font-size: 15px;
    padding: 5px;
    margin-right:140px;
  }
  
  nav{
  padding-bottom: 10px;
  height: 270px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  }
  
  main{
    flex-direction: column-reverse;
    height: 1500px;
  }
  
  .cart-click{
    background-color: rgb(140, 149, 157);
    padding: 5%;
    z-index: 1;
    width: 100px;
    height: 90px;
    border-radius: 25px;
    margin-bottom: 0;
    padding: 0;
  }
  
  h2{
    font-size: 14px;
  }

  a{
    font-size: 12px;
  }

  .view-cart{
    margin-top: -40px;
  }

  .cart-logo{
    width: 45px;
    height: 5px;

  }

  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    height: 220px;
  }
  .Market{
   width: 77%;
   height: 90;
   margin-top: -300px;
 }
.item-map{
  display: flex;
  align-items:center ;
  padding-left: 70px;
}

.item{
  margin: 0 0 0 0;
}

#toucan{
  height: 40px;
  width: 27px;
  margin-right: 10px;
}



  }
